@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;500&display=swap");

body {
  margin: 0;
  font-family: "Raleway", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #22358b;
}

.content {
  flex-grow: 1;
}

.table-responsive {
  margin-bottom: 1rem;
  color: #212529;
}

.table-responsive-screen {
  max-height: 90vh !important;
  overflow-y: auto;
  overflow-x: auto;
}

.no-wrap {
  white-space: nowrap !important;
}

label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  /* max-width: 350px !important; */
  padding: 20px;
}

.card {
  background-color: #f7f7f7;
  /* padding: 20px 25px 30px; */
  margin: 0 auto 25px;
  margin-top: 10px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.btn-primary:disabled {
  background-color: #22358b;
  border-color: #22358b;
}

.page-img {
  width: 64px;
  margin: 0 auto 10px;
  display: none;
  /* -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%; */
}

.page-title {
  text-align: center;
  /* margin-top: 15px; */
  font-size: 26px;
  color: #22358b;
  text-decoration: underline;
}

.jumbotron {
  padding: 1rem 2rem;
}

.logo {
  width: 260px;
  margin-right: 25px;
}

.navbar-blue {
  background-color: #22358b;
}

.swing-export-link {
  color: whitesmoke !important;
}

.nav-link {
  color: whitesmoke !important;
  margin-left: 20px;
}

.date-picker {
  width: 100% !important;
  /* display: block !important; */
}

.react-datepicker-wrapper {
  display: inherit !important;
}

.trailer-container {
  padding: 25px;
}

label {
  font-size: 15px;
  color: #22358b;
}

.btn-primary {
  margin-top: 2px;
  margin-right: 5px;
  padding-left: 13px;
  padding-right: 13px;
  font-size: 15px;
  background-color: #00167c;
}

.btn-outline-primary {
  margin-top: 2px;
  margin-right: 5px;
  padding-left: 13px;
  padding-right: 13px;
  font-size: 15px;
  color: #00167c;
  border-color: #00167c;
}

.btn-outline-primary:not(:disabled):not(.disabled).active {
  background-color: #00167c;
  color: #fff;
  border: none;
}

.btn-outline-primary:hover {
  background-color: #00167c;
  color: #fff;
}

.btn-grid {
  background-color: #22358b;
  margin-top: 5px;
  width: 100%;
}

.danger {
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-color: #dc3545;
}

.danger:hover {
  background-color: rgba(220, 53, 69, 0.6);
  border-color: #dc3545;
}

.info {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.info:hover {
  color: #000;
  background-color: #017a92;
  border-color: #0dcaf0;
}

/* .profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
} */

.right {
  text-align: right;
}

.store-banner {
  padding-top: 15px;
  width: 50%;
}

.dash-guest {
  text-align: center;
}

.required {
  font-weight: 500;
  font-size: 26px;
}

.required-text {
  text-align: right;
  font-size: 15px;
}

.posting-message {
  padding-top: 30px;
}

.job-information {
  font-weight: 500;
  margin-bottom: 0;
  margin-left: 7px;
}

.job-label {
  margin-bottom: 0;
  font-size: 15px;
  text-transform: capitalize;
}

.address-container {
  padding: 0;
}

.job-description {
  margin-bottom: 20px;
  font-size: 25px;
  text-transform: capitalize;
}

.card {
  border-radius: 0.3rem;
}

.customer-container {
  text-align: right;
}

.status-icon {
  margin-bottom: 0px;
}

.icon-img {
  width: 32px;
  margin: auto;
}

.status-text {
  font-weight: 600;
  margin-bottom: 0;
}

.positive-status {
  color: green;
}

.amber-status {
  color: orange;
}

.negative-status {
  color: red;
}

.center {
  text-align: center;
}

.filters-container {
  margin: 25px 0px 25px 0px;
  padding: 5px;
  /* border: 1px solid #4f68dd; */
  /* border-radius: 5px; */
}

.filters-title {
  font-size: 22px;
}

.filter-item {
  margin-left: 2px;
  text-align: center;
  width: 100%;
  height: 100%;
  padding: 10px 5px 5px 5px;
  cursor: pointer;
}

.filter-text {
  font-size: 14px;
  margin-bottom: 0;
  font-weight: 600;
}
.filter-icon {
  width: 35px;
  margin-bottom: 7px;
}

.export-container {
  float: right;
  text-align: right;
  margin-top: 15px;
}

.vehicle-move-status {
  text-align: center;
}
.deselected-filter {
  opacity: 0.5;
}

.action-btn {
  margin-top: 15px;
  width: 100%;
}

.sk-circle {
  margin: 20px auto;
  width: 65px;
  height: 65px;
  position: relative;
}

.sk-circle-sm {
  margin: 5px auto;
  width: 25px;
  height: 25px;
}

.sk-circle .sk-child {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.sk-circle .sk-child:before {
  content: "";
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #22358b;
  border-radius: 100%;
  -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
  animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
}
.sk-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}
.sk-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}
.sk-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.sk-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}
.sk-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}
.sk-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.sk-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}
.sk-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}
.sk-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
.sk-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}
.sk-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}
.sk-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.sk-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.sk-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.sk-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
.sk-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}
.sk-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}
.sk-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}
.sk-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}
.sk-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}
.sk-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}
.sk-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleBounceDelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes sk-circleBounceDelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.input-note {
  font-size: 12px;
  color: grey;
  text-align: right;
}

.radio-label {
  text-align: center;
}

.radio-label input {
  margin: 5px;
}

.column-card {
  margin-left: 15px;
  display: inline-block;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.vehicle-title {
  margin-left: 25px;
  margin-top: 35px;
  font-size: 25px;
}

.filter-section {
  margin-bottom: 25px;
}

.card-columns-double {
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
}

.w-full {
  width: 100%;
}

.loading-container {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.x-small {
  font-size: 60%;
  margin-top: 5px;
}

@media screen and (max-width: 1024px) {
  .logo {
    width: 145px;
  }
}
